/* eslint-disable */
import { TRADE_TYPE_LABELS } from '@/utils/constant/paymentConst'
export const balanceCols = [
  {
    prop: 'orderCode',
    label: '订单号',
    minWidth: '200',
    formatter(row) {
      return row?.orderDTO?.orderCode || '暂无'
    }
  },
  {
    prop: 'tradeType',
    label: '交易类型',
    minWidth: '200',
    formatter(row) {
      return TRADE_TYPE_LABELS[row.tradeType]
    }
  },
  {
    prop: 'tradeTime',
    label: '使用时间',
    minWidth: '200',
    slotName: 'tradeTime',
    sortable: true
  },
  {
    prop: 'tradeAmount',
    label: '使用的额度',
    minWidth: '200',
    formatter(row) {
      if (row.$tradeAmount && row.tradeType != 12) return row.$tradeAmount
      if (row.tradeType == 12) return `-${row.orderDTO.externalFreight}`
      if (row.tradeAmount === 0) return (row.$tradeAmount = 0)
      return (row.$tradeAmount = `${row.isIncome === 1 ? '+' : '-'}${row.tradeAmount}`)
    }
  },
  {
    prop: 'creditMonthBillId',
    label: '状态',
    minWidth: '200',
    slotName: 'creditMonthBillId'
  },
  {
    prop: 'tradeTitle',
    label: '备注',
    minWidth: '200'
  }
]
